<template>
  <Loading v-if="initLoading" />
  <div class="payment" v-else>
    <Header callBack @click="goBack"> 订单详情 </Header>
    <div class="staCon">
      <!-- 等待付款top -->
      <div
        class="statusBox"
        v-if="orderType == 1 && detailsCleared.status == 1"
      >
        <img src="../../../static/image/await.png" alt="" />
        <div class="payment_text">寄卖中</div>
      </div>

      <div
        class="statusBox"
        v-if="orderType == 2 && detailsCleared.status == 1"
      >
        <img src="../../../static/image/await.png" alt="" />
        <div class="payment_text">等待付款</div>
        <div class="timeOut">
          <i v-if="detailsCleared.income_time > 0">付款倒计时:</i>
          {{ detailsCleared.income_time | timeFormate }}
        </div>
      </div>

      <div
        class="statusBox"
        v-if="
          (orderType == 1 && detailsCleared.status == 2) ||
          (orderType == 2 && detailsCleared.status == 3)
        "
      >
        <img src="../../../static/image/quxiao.png" alt="" />
        <div class="quxiao">已取消</div>
      </div>

      <div
        class="statusBox"
        v-if="
          (orderType == 1 && detailsCleared.status == 3) ||
          (orderType == 2 && detailsCleared.status == 2)
        "
      >
        <img src="../../../static/image/succeed.png" alt="" />
        <div class="succeed">已完成</div>
        <div
          v-if="isShowtimeOut"
          style="font-size: 12px; margin-top: 10px; color: #999999"
        >
          <span style="color: #0754d3">{{ time }}s</span> 后自动跳转到我的藏品
        </div>
      </div>

      <!-- 申购 -->
      <div
        class="statusBox"
        v-if="orderType == 3 && detailsCleared.final_state == 1"
      >
        <img src="../../../static/image/await.png" alt="" />
        <div class="payment_text">申购中</div>
      </div>
      <div
        class="statusBox"
        v-if="orderType == 3 && detailsCleared.final_state == 2"
      >
        <img src="../../../static/image/succeed.png" alt="" />
        <div class="succeed">申购成功</div>
      </div>
      <div
        class="statusBox"
        v-if="orderType == 3 && detailsCleared.final_state == 3"
      >
        <img src="../../../static/image/succeed.png" alt="" />
        <div class="succeed" v-if="detailsCleared.status == 1">申购结果</div>
        <div class="succeed" v-else>申购结束，等待分配</div>
      </div>
      <div
        class="statusBox"
        v-if="orderType == 3 && detailsCleared.final_state == 4"
      >
        <img src="../../../static/image/quxiao.png" alt="" />
        <div class="quxiao">取消申购</div>
      </div>

      <!-- 中间 -->
      <div class="middleCon">
        <img :src="detailsCleared.listPic" alt="" />
        <div>
          <div class="middle_text" v-if="$route.query.orderType == 2">
            {{ detailsCleared.goods_name }}
          </div>
          <div class="middle_text" v-else>{{ detailsCleared.name }}</div>
          <div class="middle_hao" v-if="$route.query.orderType == 3">
            数量: {{ detailsCleared.goods_num }}
          </div>
          <div class="middle_hao" v-else>{{ detailsCleared.goodsNumber }}</div>
        </div>
      </div>

      <!-- 购买订单 -->
      <div v-if="$route.query.orderType == 2">
        <div class="message_box">
          <div class="purchase">
            <div class="purchase_name">购买信息</div>
            <div class="purchase_messy">{{ detailsCleared.nickName }}</div>
          </div>
          <div class="purchase">
            <div class="purchase_name">创建时间</div>
            <div class="purchase_messy">{{ detailsCleared.created_at }}</div>
          </div>
          <div class="purchase" v-if="detailsCleared.status == 2">
            <div class="purchase_name">完成时间</div>
            <div class="purchase_messy" v-if="this.$route.query.buy == 2">
              {{ detailsCleared.pay_time }}
            </div>
            <div class="purchase_messy" v-else>
              {{ detailsCleared.updated_at || detailsCleared.created_at }}
            </div>
          </div>
          <div class="purchase" v-if="detailsCleared.status == 3">
            <div class="purchase_name">取消时间</div>
            <div class="purchase_messy">
              {{ detailsCleared.updated_at || detailsCleared.created_at }}
            </div>
          </div>

          <div class="purchase">
            <div class="purchase_name">订单编号</div>
            <div class="purchase_messy">
              <span class="content">{{
                detailsCleared.status === 2
                  ? detailsCleared.order_no
                  : detailsCleared.consignment
              }}</span>
              <span
                class="copy"
                @click="
                  copy(
                    detailsCleared.status === 2
                      ? detailsCleared.order_no
                      : detailsCleared.consignment
                  )
                "
              >
                <img src="../../../static/image/fuzhi@2x.png" alt="" />
              </span>
            </div>
          </div>

          <div class="purchase">
            <div class="purchase_name">订单金额</div>
            <div class="purchase_messy">￥{{ detailsCleared.pay_price }}</div>
          </div>
        </div>
        <div class="message_box">
          <div class="purchase">
            <div class="purchase_name">总金额</div>
            <div class="purchase_num">￥{{ detailsCleared.pay_price }}</div>
          </div>
        </div>
      </div>
      <!-- 寄售订单 -->
      <div v-else-if="$route.query.orderType == 1">
        <div class="message_box">
          <div class="purchase">
            <div class="purchase_name">创建时间</div>
            <div class="purchase_messy">{{ detailsCleared.created_at }}</div>
          </div>

          <div class="purchase" v-if="detailsCleared.status == 3">
            <div class="purchase_name">完成时间</div>
            <div class="purchase_messy">
              {{ detailsCleared.updated_at || detailsCleared.created_at }}
            </div>
          </div>
          <div class="purchase" v-if="detailsCleared.status == 2">
            <div class="purchase_name">取消时间</div>
            <div class="purchase_messy">
              {{ detailsCleared.updated_at || detailsCleared.created_at }}
            </div>
          </div>

          <div class="purchase">
            <div class="purchase_name">订单编号</div>
            <div class="purchase_messy">
              <span class="content">{{
                detailsCleared.status == 3
                  ? detailsCleared.order_no
                  : detailsCleared.number
              }}</span>
              <span
                class="copy"
                @click="
                  copy(
                    detailsCleared.status == 3
                      ? detailsCleared.order_no
                      : detailsCleared.number
                  )
                "
              >
                <img src="../../../static/image/fuzhi@2x.png" alt="" />
              </span>
            </div>
          </div>

          <div class="purchase">
            <div class="purchase_name">订单金额</div>
            <div class="purchase_messy">￥{{ detailsCleared.fee }}</div>
          </div>
          <div class="purchase">
            <div class="purchase_name">手续费</div>
            <div class="purchase_messy">
              -￥{{ detailsCleared.poundagefee }}
            </div>
          </div>
          <div class="purchase">
            <div class="purchase_name">版权费</div>
            <div class="purchase_messy">
              -￥{{ detailsCleared.copyright_fee }}
            </div>
          </div>
        </div>
        <div class="message_box">
          <div class="purchase">
            <div class="purchase_name">总金额</div>
            <div class="purchase_num">￥{{ detailsCleared.fee_zong }}</div>
          </div>
        </div>
      </div>
      <!-- 申购信息 -->
      <div v-else>
        <div class="message_box">
          <div class="purchase">
            <div class="purchase_name">购买信息</div>
            <div class="purchase_messy">{{ detailsCleared.nickName }}</div>
          </div>
          <div class="purchase">
            <div class="purchase_name">创建时间</div>
            <div class="purchase_messy">{{ detailsCleared.created_at }}</div>
          </div>
          <div class="purchase" v-if="detailsCleared.final_state == 1">
            <div class="purchase_name">申购时间</div>
            <div class="purchase_messy">
              {{ detailsCleared.created_at }}
            </div>
          </div>
          <div
            class="purchase"
            v-if="[2, 3, 4].includes(detailsCleared.final_state)"
          >
            <div class="purchase_name" v-if="detailsCleared.final_state == 2">
              完成时间
            </div>
            <div class="purchase_name" v-if="detailsCleared.final_state == 3">
              结束时间
            </div>
            <div class="purchase_name" v-if="detailsCleared.final_state == 4">
              取消时间
            </div>
            <div class="purchase_messy">
              {{ detailsCleared.updated_at }}
            </div>
          </div>

          <div class="purchase">
            <div class="purchase_name">订单编号</div>
            <div class="purchase_messy">
              <span class="content">{{ detailsCleared.order_no }}</span>
              <span class="copy" @click="copy(detailsCleared.order_no)">
                <img src="../../../static/image/fuzhi@2x.png" alt="" />
              </span>
            </div>
          </div>

          <div class="purchase">
            <div class="purchase_name">订单金额</div>
            <div class="purchase_messy">￥{{ detailsCleared.pay_price }}</div>
          </div>
          <div class="purchase">
            <div class="purchase_name">支付方式</div>
            <div class="purchase_messy">
              {{
                detailsCleared.pay_channel == 18
                  ? "余额支付"
                  : detailsCleared.pay_channel == 21
                  ? "易宝支付"
                  : "支付宝支付"
              }}
            </div>
          </div>
          <div class="purchase">
            <div class="purchase_name">申购市值</div>
            <div class="purchase_messy">
              ¥{{ detailsCleared.multiple_price }}
            </div>
          </div>
          <div
            class="purchase"
            v-if="
              !(
                [2, 4].includes(detailsCleared.final_state) ||
                (detailsCleared.final_state == 3 && detailsCleared.status !== 0)
              ) && detailsCleared.coupon_num
            "
          >
            <div class="purchase_name">使用申购中签券数量</div>
            <div class="purchase_num" style="color: #333">
              {{ detailsCleared.coupon_num }}张
            </div>
          </div>
        </div>
        <div
          class="message_box"
          v-if="
            [2, 4].includes(detailsCleared.final_state) ||
            (detailsCleared.final_state == 3 && detailsCleared.status !== 0)
          "
        >
          <div class="purchase" v-if="detailsCleared.coupon_num">
            <div class="purchase_name">使用申购中签券数量</div>
            <div class="purchase_num" style="color: #333">
              {{ detailsCleared.coupon_num }}张
            </div>
          </div>
          <div class="purchase">
            <div class="purchase_name">获得数量</div>
            <div class="purchase_num" style="color: #333">
              {{ detailsCleared.give_number }}
            </div>
          </div>
          <div class="purchase">
            <div class="purchase_name">退回金额</div>
            <div class="purchase_num" style="color: #333">
              ￥{{ detailsCleared.money_return }}
            </div>
          </div>
        </div>
        <div class="message_box">
          <div class="purchase">
            <div class="purchase_name">总金额</div>
            <div class="purchase_num">￥{{ detailsCleared.pay_price }}</div>
          </div>
        </div>
      </div>

      <div
        class="but_box active"
        @click="showDialog1"
        v-if="orderType == 3 && detailsCleared.final_state == 1"
      >
        取消申购
      </div>
      <div
        class="but_box"
        @click="showDialog"
        v-if="orderType == 1 && detailsCleared.status == 1"
      >
        取消寄卖
      </div>

      <div v-if="isShowtimeOut" class="lookList" @click="lookList">
        查看藏品
      </div>

      <!-- <div
        class="buy_box"
        @click="againBuy"
        v-if="orderType == 2 && detailsCleared.status == 3"
      >
        继续支付
      </div> -->

      <div class="stayBox" v-if="orderType == 2 && detailsCleared.status == 1">
        <div class="continue" @click="contPay">
          {{
            detailsCleared.pay_channel == 18
              ? "余额支付"
              : detailsCleared.pay_channel == 21
              ? "易宝支付"
              : "支付宝支付"
          }}
        </div>
        <div class="cancels" @click="mystery = true">取消订单</div>
      </div>
    </div>

    <div class="selectPayType" v-if="showPayBox">
      <div class="selectPayTypeBox"></div>
      <div class="bg">
        <div class="close" @click.stop="showPayBox = false">
          <img src="../../../static/image/close.png" alt="" />
        </div>
        <div class="title">提示</div>
        <div class="content">请在支付宝-账单内完成支付</div>
        <div class="btn" @click="confirmPay">打开支付宝</div>
      </div>
    </div>

    <!-- 是否支付完成 -->
    <div class="selectPayType" v-if="paymentCompleted">
      <div class="selectPayTypeBox"></div>
      <div class="bg">
        <div class="close" @click.stop="paymentCompleted = false">
          <img src="../../../static/image/close.png" alt="" />
        </div>
        <div class="title">提示</div>
        <div class="content">是否完成支付?</div>
        <div class="btnGroup">
          <div class="completed" @click="paymentCompletedCb">已完成</div>
          <div class="unCompleted" @click="paymentCompletedCb">未完成</div>
        </div>
      </div>
    </div>

    <div class="payBox" v-if="false">
      <div class="payBoxBg"></div>
      <div class="payBoxContent">
        <div class="head">
          <div class="close" @click.stop="showPayBox = false">
            <img src="../../../static/image/close.png" alt="" />
          </div>
          <div class="title">选择支付方式</div>
        </div>
        <div class="content">
          <div class="pic">¥{{ detailsCleared.pay_price }}</div>
          <div class="payType">支付方式</div>

          <van-radio-group v-model="payTypeInfo.active">
            <van-cell-group inset>
              <van-cell
                v-for="item in payTypeInfo.list"
                :key="item.id"
                @click.stop="clickPayType(item)"
              >
                <template #title>
                  <div class="payTypeList">
                    <img :src="item.icon" alt="" />
                    <span class="payTypeName"
                      >{{ item.name }}
                      <i v-if="item.id === 0">({{ balance }})</i>
                    </span>
                  </div>
                </template>
                <template #right-icon>
                  <div v-if="item.id === 2">
                    <img
                      src="../../../static/image/right.png"
                      style="width: 10px; margin-right: 5px"
                      alt=""
                    />
                  </div>
                  <van-radio :name="item.id" v-else />
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>

          <div class="btn" @click="confirmPay">确认支付</div>
        </div>
      </div>
    </div>

    <van-overlay :show="showPwd">
      <div class="wap">
        <div class="wap_affirm">
          <div class="cha" @click="showPwd = false">×</div>
          <div>请输入交易密码</div>
          <div class="forgotPwd" @click="$router.push('/forgotPwd')">
            忘记密码
          </div>
        </div>
        <!-- 密码输入框 -->
        <van-password-input
          :value="value"
          :gutter="10"
          :focused="showKeyboard"
          @focus="showKeyboard = true"
          @input="specifiName($event)"
        />
        <!-- 数字键盘 -->
        <van-number-keyboard
          :show="showKeyboard"
          @input="onInput"
          @delete="onDelete"
          @blur="showKeyboard = true"
        />
      </div>
    </van-overlay>

    <van-overlay :show="mystery">
      <div class="mystery_nei">
        <div class="mystery_block">
          <div class="mystery_text">是否确认取消？</div>
          <div class="mystery_but">
            <div class="mystery_one" @click="mystery = false">取消</div>
            <div class="mystery_tow" @click="cancelOrder">确认</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { Dialog } from "vant";
import remaining from "../../../static/image/remaining.png";
import zhifubao from "../../../static/image/icon.png";
import bank from "../../../static/image/icon@2x.png";
import { isSafari } from "../../utils/tools";
export default {
  data() {
    return {
      detailsCleared: {},
      isShowtimeOut: false,
      time: 5,
      status: "",
      id: "",
      aa: "",
      showPayBox: false,
      mystery: false,
      orderType: "", // 1 寄卖，2 购买
      balance: 0,
      payTypeInfo: {
        list: [
          { id: 0, name: "余额", icon: remaining },
          { id: 1, name: "支付宝", icon: zhifubao },
          // { id: 2, name: '银行卡', icon: bank },
        ],
        active: 0,
      },
      showPwd: false,
      showKeyboard: true,
      paymentCompleted: false,
      value: "",
      timer: null,
      timeOutTimer: null,
      orderNo: "",
      isOpenAliPay: false,
      initLoading: true,
    };
  },
  filters: {
    timeFormate(val) {
      const m = Math.floor(val / 60);
      if (val <= 0) {
        return "支付超时";
      }
      return `${m}分 ${val - m * 60}秒`;
    },
  },
  beforeDestroy() {
    if (this.timeOutTimer) {
      clearInterval(this.timeOutTimer);
      this.timeOutTimer = null;
    }
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    document.removeEventListener("visibilitychange", this.cb);
  },
  // 页面加载
  mounted() {
    this.initLoading = true;
    // if (isSafari()) {
    document.addEventListener("visibilitychange", this.cb);
    // }
    let query = this.$route.query;
    this.orderType = query.orderType; // 订单类型
    this.id = this.$route.query.id; // id
    this.getOrderDetail(this.id, this.orderType);
    if (this.$route.query.orderType == 2) {
      this.getBalance();
    }
    if (this.$route.query.isShowTimeOut == 1) {
      this.timeOut();
    }
  },
  methods: {
    cb() {
      if (document.hidden) {
        // 页面被挂起
        console.log("页面被挂起");
      } else {
        console.log("页面呼出");
        if (this.isOpenAliPay) {
          this.paymentCompleted = true;
          this.isOpenAliPay = false;
        }
        // 页面呼出 支付宝购买后返回，刷新次数接口
        // const query = {
        //   token: localStorage.getItem('token'),
        //   order_no: this.orderNo,
        // };
        // this.$api.loop(query).then((e) => {
        //   if (e.code != 100000) return this.$toast(e.msg);
        //   this.info = JSON.stringify(e.data);
        //   if (e.data.status === 2) {
        //     // this.$router.replace('/Allorder?type=2');
        //     window.location.reload();
        //     return this.$toast('支付成功');
        //   } else {
        //     this.showPayBox = false;
        //     // window.location.reload();
        //     // this.$router.back();
        //     return this.$toast('支付失败');
        //   }
        // });
      }
    },
    goBack() {
      if (this.$route.query.isShowTimeOut == 1) {
        this.$router.go(-2);
      } else {
        this.$router.back();
      }
    },
    paymentCompletedCb() {
      this.paymentCompleted = false;
      this.getOrderDetail(this.id, this.orderType);
      if (this.$route.query.orderType == 2) {
        this.getBalance();
      }
      // this.timeOut();
    },
    // 点击复制
    copy(url) {
      //新建一个文本框
      let oInput = document.createElement("input");
      //赋值给文本框
      oInput.value = url;
      document.body.appendChild(oInput);
      // 选择对象;
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      //复制完成删除掉输入框
      oInput.remove();
      this.$toast("复制成功");
    },
    // 获取余额
    getBalance() {
      let params1 = {
        token: localStorage.getItem("token"),
      };
      this.$api.withdrawal(params1).then((res) => {
        if (res.code == 100000) {
          this.balance = res.data.fee;
        }
      });
    },
    getOrderDetail(id, type) {
      let params = {
        token: localStorage.getItem("token"),
        id: id,
        type,
      };
      this.$api.detailsCleared(params).then(
        (res) => {
          this.initLoading = false;
          if (res.code == 100000) {
            this.detailsCleared = res.data;
            this.setTime();
            if (this.orderType == 2 && this.detailsCleared.status == 1) {
              this.$api.payChannel().then((e) => {
                if (e.code === 0) {
                  if (
                    (e.data || {}).pay_channel != 1 &&
                    this.detailsCleared.pay_channel != 21
                  ) {
                    this.detailsCleared.pay_channel = 18;
                  }
                }
              });
            }
          }
        },
        () => {
          this.initLoading = false;
        }
      );
    },
    setTime() {
      this.timer = setInterval(() => {
        if (--this.detailsCleared.income_time <= 0) {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    // 取消寄卖
    showDialog() {
      Dialog.confirm({
        title: "提示",
        message: "是否确认取消寄卖？",
      })
        .then(() => {
          // 确认
          let params = {
            id: this.id,
            token: localStorage.getItem("token"),
          };

          this.$api.anyCancel(params).then((res) => {
            this.$toast(res.msg);
            if (res.code == 0) {
              this.$router.back();
              return;
            }
          });
        })
        .catch(() => {
          // 取消
        });
    },
    // 取消寄卖
    showDialog1() {
      Dialog.confirm({
        title: "提示",
        message: "是否确认取消申购？",
      })
        .then(() => {
          // 确认
          let params = {
            order_id: this.detailsCleared.id,
            token: localStorage.getItem("token"),
          };

          this.$api.shengouCancle(params).then((res) => {
            this.$toast(res.msg);
            if (res.code == 0) {
              this.$router.back();
              return;
            }
          });
        })
        .catch(() => {
          // 取消
        });
    },
    // 再次购买
    againBuy() {
      this.$router.push({
        path: "/particulars",
        query: {
          id: this.detailsCleared.entrust_id,
          add: 1,
        },
      });
    },
    // 继续支付
    contPay() {
      // this.payPic = this.detailsCleared.pay_price;
      // this.orderNo = this.detailsCleared.order_no;
      if (this.detailsCleared.pay_channel == 18) {
        this.payTypeInfo.active = 0;
        this.showPwd = true;
      } else if (this.detailsCleared.pay_channel == 21) {
        this.$router.push("/yibaoxd?order_no=" + this.detailsCleared.order_no);
      } else {
        this.payTypeInfo.active = 1;
        this.showPayBox = true;
      }
    },
    // 取消订单
    cancelOrder() {
      let params = {
        token: localStorage.getItem("token"),
        id: this.detailsCleared.id,
      };
      this.$api.cancelUserOrder(params).then((res) => {
        this.$toast(res.msg);
        if (res.code == 0) {
          this.$router.back();
        }
      });
    },
    clickPayType(item) {
      if (item.id === 2) {
        return this.$toast("未获取银行卡信息");
      }
    },
    // 确认支付
    confirmPay() {
      this.$api.payChannel().then((e) => {
        if (e.code === 0) {
          if ((e.data || {}).pay_channel != 1) {
            this.detailsCleared.pay_channel = 18;
            this.$toast("请使用余额支付");
          } else {
            this.isOpenAliPay = true;
            window.open("alipays://platformapi/startapp?appId=20000003");
          }
        }
      });
      this.showPayBox = false;
      // this.showPayBox = false;
      // this.value = '';
      // if (this.payTypeInfo.active === 1) {
      //   // 支付宝
      //   let params = {
      //     token: localStorage.getItem('token'),
      //     pay_channel: 'alipay_wap', // 余额支付
      //     id: this.detailsCleared.id,
      //   };
      //   this.$api.pendingPayment(params).then((res) => {
      //     if (res.code == 100000) {
      //       this.orderNo = res.data.order_no;
      //       localStorage.setItem('pay', 2);
      //       if (isSafari()) {
      //         window.location.href = res.data.expend.pay_info;
      //       } else {
      //         window.location.href = res.data.expend.pay_info;
      //         // this.$router.push({
      //         //   path: '/Pay',
      //         //   query: {
      //         //     payUrl: res.data.expend.pay_info,
      //         //     orderNo: res.data.order_no,
      //         //     orderType: this.orderType,
      //         //     id: this.detailsCleared.id,
      //         //   },
      //         // });
      //       }
      //     } else {
      //       this.$toast(res.msg);
      //     }
      //   });
      // } else {
      //   this.showPwd = true;
      // }
    },
    timeOut() {
      this.isShowtimeOut = true;
      this.timeOutTimer = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          clearInterval(this.timeOutTimer);
          this.timeOutTimer = null;
          this.$router.replace("/user");
        }
      }, 1000);
    },
    lookList() {
      this.$router.replace("/user");
      if (this.timeOutTimer) {
        clearInterval(this.timeOutTimer);
        this.timeOutTimer = null;
      }
    },

    onInput(key) {
      if (this.value.length > 5) return;
      this.value = (this.value + key).slice(0, 6);
      if (this.value.length === 6) {
        // 余额
        if (this.payTypeInfo.active === 0) {
          let params = {
            token: localStorage.getItem("token"),
            pay_channel: "balance", // 余额支付
            id: this.detailsCleared.id,
            payPassword: this.value,
          };
          this.$api.pendingPayment(params).then((res) => {
            if (res.code == 100000) {
              this.$toast(res.msg);
              this.showPwd = false;
              this.value = false;
              this.getOrderDetail(this.id, this.orderType);
              if (this.$route.query.orderType == 2) {
                this.getBalance();
              }
              // this.timeOut();
              // this.$router.push('/user');
            } else {
              this.$toast(res.msg);
              this.value = "";
            }
          });
        }
      }
    },
    onDelete() {
      this.value = this.value.slice(0, this.value.length - 1);
    },
  },
};
</script>
<style lang="less" scoped>
.payment {
  width: 100%;
  height: 100vh;
}
.staCon {
  width: 100%;
  height: 100%;
  background: #f3f4f8;
  padding: 12.8vw 4.4vw 12.8vw;
  box-sizing: border-box;
}
.statusBox {
  margin-bottom: 10.93vw;
  width: 100%;
  text-align: center;
  font-size: 20px;
  img {
    width: 53px;
    height: 53px;
  }
}
.timeOut {
  font-size: 12px;
  margin-top: 5px;
  color: #e78e10;
}
.payment_text {
  color: #e78e10;
}
.succeed {
  color: #009944;
}
.quxiao {
  color: #cecece;
}

.but_box,
.buy_box,
.stayBox div {
  width: 53.33vw;
  height: 10.67vw;
  border-radius: 1.33vw;
  text-align: center;
  font-size: 4vw;
  line-height: 10.67vw;
}

.but_box {
  background: #ebebeb;
  color: #e76810;
  margin: 0 auto;
  margin-top: 10.4vw;
  &.active {
    background: #0754d3;
    color: #fff;
  }
}
.buy_box {
  border: 1px solid #e76810;
  color: #e76810;
  margin: 0 auto;
  margin-top: 10.4vw;
}
.message_box {
  margin-top: 2.67vw;
  width: 100%;
  background: #ffffff;
  border-radius: 1.87vw;
  padding: 2.67vw 5.6vw;
  box-sizing: border-box;
  line-height: 2;
  .purchase {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 3.47vw;

    .purchase_name {
      color: #999999;
    }
    .purchase_messy {
      font-size: 12px;
      color: #333333;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .content {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .copy {
        margin-left: 5px;
        img {
          width: 12px;
        }
      }
    }
    .purchase_num {
      color: #e76810;
    }
  }
}
.lookList {
  width: 100px;
  height: 35px;
  background: #0754d3;
  border-radius: 5px;
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 0;
}
.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
.payment_time {
  font-size: 15px;
  margin-top: 0.5rem;
}

.middleCon {
  width: 100%;
  background: #ffffff;
  display: flex;
  border-radius: 1.33vw;
  align-items: center;
  padding: 3.07vw 2.67vw 3.87vw;
  box-sizing: border-box;
  img {
    width: 13.33vw;
    height: 13.33vw;
    margin-right: 3.47vw;
  }
  > div {
    .middle_text {
      font-size: 3.73vw;
      color: #000;
    }
    .middle_hao {
      color: #999;
      font-size: 3.2vw;
      margin-top: 2.67vw;
    }
  }
}

.stayBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10.4vw;
  .continue {
    background: #0754d3;
    color: #fff;
  }
  .cancels {
    border: 2px solid #e76810;
    color: #e87122;
    margin-top: 4vw;
  }
}

.payBox {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .payBoxBg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
  }
  .payBoxContent {
    background: #fff;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
    .head {
      padding: 10px;
      position: relative;
      .title {
        font-size: 18px;
        color: #333;
        text-align: center;
      }
      .close {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 15px;
        img {
          width: 100%;
        }
      }
    }
    .content {
      .pic {
        font-size: 20px;
        font-weight: bold;
        color: #e76810;
        text-align: center;
        margin: 20px 0;
      }
      .payType {
        font-size: 14px;
        font-weight: bold;
        padding: 0 20px;
      }
    }
  }
}
/deep/.van-cell-group--inset {
  margin: 0;
}
/deep/.van-cell {
  padding: 10px 20px;
}
.payTypeList {
  display: flex;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
    vertical-align: middle;
    margin-right: 5px;
  }
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  margin: 20px auto;
  border-radius: 3px;
  width: 180px;
  height: 38px;
  background: #0754d3;
  border-radius: 5px;
  font-size: 14px;
}
/deep/.van-password-input__item--focus {
  border: 2px #666666 solid;
  border-radius: 5px;
}
/deep/.van-password-input__item {
  //   background-color: #999999;
  border-radius: 5px;
  border: 2px #999999 solid;
  box-sizing: border-box;
}
/deep/.van-password-input {
  margin-bottom: 20px;
}
/deep/.van-number-keyboard {
  position: relative;
}
.wap {
  background-color: #ffffff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding-top: 10px;
}
// .cha {
//   color: #666666;
//   font-size: 30px;
//   margin-left: 20px;
// }
// .wap_affirm {
//   font-size: 17px;
//   // margin-top: 240px;
//   display: flex;
//   font-size: 20px;
//   margin-bottom: 20px;
//   width: 100%;
//   line-height: 60px;
//   .cha {
//     font-size: 40px;
//     color: #999999;
//     margin-right: 85px;
//     margin-left: 10px;
//   }
// }
.wap_affirm {
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  div {
    flex: 2;
    text-align: center;
    font-size: 16px;
  }
  .cha {
    flex: 1;
    color: #666666;
    text-align: left;
    font-size: 30px;
  }
  .forgotPwd {
    flex: 1;
    text-align: right;
    font-size: 14px;
    color: rgb(rgb(7 84 211));
  }
}
.selectPayType {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  .selectPayTypeBox {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
  .bg {
    width: 80%;
    height: 200px;
    background: #ffffff;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .close {
      position: absolute;
      right: 10px;
      top: 0;
      width: 15px;
      img {
        width: 100%;
      }
    }
    .title {
      font-size: 20px;
      font-weight: bold;
      color: #000000;
      text-align: center;
      margin-top: 25px;
    }
    .content {
      font-size: 16px;
      color: #666666;
      margin: 20px 0 35px;
      text-align: center;
    }
    .btn {
      width: 60%;
      height: 35px;
      margin: 0 auto;
      background: #0754d3;
      border-radius: 3px;
    }
    .btnGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      div {
        background: #0754d3;
        border-radius: 3px;
        font-size: 14px;
        color: #fff;
        padding: 5px 15px;
        border: 1px solid transparent;
        &.unCompleted {
          border: 1px solid #0754d3;
          background: transparent;
          color: #0754d3;
        }
      }
    }
  }
}

.mystery_nei {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .mystery_block {
    background-color: #fff;
    width: 250px;
    height: 150px;
    border-radius: 10px;
    .mystery_text {
      font-size: 16px;
      text-align: center;
      line-height: 50px;
      margin-top: 20px;
    }
    .mystery_but {
      display: flex;
      font-size: 14px;
      justify-content: space-around;
      margin-top: 20px;
      .mystery_tow {
        height: 30px;
        line-height: 30px;
        width: 80px;
        text-align: center;
        background-color: black;
        color: #ffffff;
      }
      .mystery_one {
        height: 30px;
        line-height: 30px;
        width: 80px;
        text-align: center;
        // background-color: ;
        border: 1px black solid;
      }
    }
  }
}
</style>
